<template>
    <div class="trade">
        <div class="trade_w">
            <van-tabs class="sale_t" v-model="saleActive" @change="saleChange">
                <van-tab title="上架中" :disabled='loading == true' name="onshelves"></van-tab>
                <van-tab title="未上架" :disabled='loading == true' name="pullshelves"></van-tab>
                <van-tab title="已出售" :disabled='loading == true' name="sold"></van-tab>
            </van-tabs>
            <van-list
                class="trade_list"
                v-model="loading"
                offset="50"
                :finished="finished"
                finished-text="没有更多了"
                :error.sync="error"
                error-text="请求失败，点击重新加载"
                @load="onLoad"

            >
                <van-cell v-for="item in list" :key="item.id" >
                    <div @click="listClick($event, item)">
                        <div class="trade_list_w">
                            <img class="profilePhoto" :src="item.imgSrc">
                            <span class="index_list_c">
                                <div class="index_list_c_t" v-if="item.onsale_type == 1">
                                    {{item.channel_name}}\{{item.job}}\{{item.lv}}级
                                    <span>{{item.server_name}}</span>
                                </div>
                                <div class="index_list_c_t" v-else-if="item.onsale_type == 3">{{item.channel_name}}\{{item.item_name}}</div>
                                <div class="index_list_c_t" v-else>{{item.channel_name}}\{{item.item_name}}×{{item.item_num}}万</div>
                                <div class="index_list_c_c" v-show="saleActive != 'pullshelves'">
                                    ￥{{item.price}}
                                </div>
                                <div class="index_list_c_b">
                                    <div class="index_list_c_b_l">
                                        <span class="index_list_c_b_t" :class="{isActive : item.isActive}" v-show="saleActive == 'onshelves'">{{item.status_name}}</span>
                                        <span class="index_list_c_b_t isActive" :class="{xiajia: item.isPull}" v-show="saleActive == 'pullshelves'">{{item.status_name}}</span>
                                        <span v-show="saleActive == 'pullshelves' && item.item_num != item.sell_coin && item.onsale_type==2 && item.channel_id==18" class="no_up_css">剩余仙玉{{item.item_num-item.sell_coin}}万</span>
                                        <span v-show="saleActive == 'pullshelves' && item.item_num != item.sell_coin && item.onsale_type==2 && item.channel_id==0" class="no_up_css">剩余元宝{{item.item_num-item.sell_coin}}万</span>
                                        <span v-show="saleActive == 'pullshelves' && item.item_num != item.sell_coin && item.onsale_type==4" class="no_up_css">剩余元宝{{item.item_num-item.sell_coin}}万</span>
                                        <span class="index_list_c_b_b" v-show="saleActive == 'onshelves'">{{item.surplus_time}}</span>
                                        <span class="index_list_c_b_b" v-show="saleActive == 'sold'">成交时间：{{item.surplus_time}}</span>
                                    </div>
                                    <div class="index_list_c_b_r">
                                        <van-button plain round type="info" size="mini" v-show="saleActive == 'onshelves' && (item.status == 2 ||item.status == 99)" @click="opeClick($event, item, 1)">下架</van-button>
                                        <p style="font-size: 0.7rem;" v-show="saleActive == 'onshelves' && (item.status == 2 ||item.status == 99) && item.sell_coin !=0">已出售{{item.sell_coin}}万</p>
                                        <van-button plain round type="info" size="mini" v-show="saleActive == 'pullshelves' && item.isPull" @click="opeClick($event, item, 2)">上架</van-button>
                                        <van-button plain round type="info" size="mini" color="#ff4e00" v-show="saleActive == 'pullshelves' && item.isPull" @click="opeClick($event, item, 3)">取回</van-button>
                                        <van-button plain round type="info" size="mini" v-show="saleActive == 'pullshelves' && !item.isPull" @click="opeClick($event, item, 4)">取消申请</van-button>
                                        <van-button plain round type="info" size="mini" color="#707070" v-show="saleActive == 'sold'" @click="opeClick($event, item, 5)">删除记录</van-button>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </van-cell>
            </van-list>
            <div class="pop_wrap">
                <van-popup v-model="pullShow" position="top">
                    <div class="pop_w">
                        <p>下架后商品从可购买列表中消失，是否确认下架？</p>
                        <p>（商品价格在下架状态下可修改）</p>
                    </div>
                    <div class="pop_btns">
                        <van-button round type="info" size="small" color="linear-gradient(to right, #FF704D, #F73727)" @click="pullConfirm">下架</van-button>
                        <van-button plain round type="default" size="small" @click="pullShow=false;">取消</van-button>
                    </div>
                </van-popup>
                <van-popup v-model="onShow" position="top"  :close-on-click-overlay="clickoverlay" @click-overlay="overlayClick">
                    <div class="pop_w">
                        <div class="on_price">
                            您将以 <span><input type="text" v-model="rolePrice" oninput = "value=value.replace(/[^\d]/g,'')" /></span> 价格重新上架商品！
                        </div>
                        <p>首次上架价格：{{firstPrice}}元</p>
                        <p class="red">*重新调整的价格不得低于或高于首次上架价格的{{percent}}！</p>
                    </div>
                    <div class="pop_btns">
                        <van-button round type="info" size="small" color="linear-gradient(to right, #FF704D, #F73727)" @click="onConfirm">上架</van-button>
                        <van-button plain round type="default" size="small" @click="cancelHandle">取消</van-button>
                    </div>
                </van-popup>
                <van-popup v-model="on_xian_yu" position="top"  :close-on-click-overlay="clickoverlay">
                    <div class="xian_yu">
                        <div class="xian_yu_scale">
                            <div v-if="channel_id==18">
                             <van-field
                                    readonly
                                    clickable
                                    name="picker"
                                    :value="scale"
                                    label="选择比例"
                                    placeholder="点击选择上架比例"
                                    @click="showPicker = true"
                                    />
                                    <van-popup v-model="showPicker" position="bottom">
                                    <van-picker
                                        show-toolbar
                                        :columns="groundingScale"
                                        @confirm="onConfirm1"
                                        @cancel="showPicker = false"
                                    />
                                    </van-popup>
                            </div>
                            <div v-else class="bing_xue_calc_box">
                                <span>出售比例：</span>1： <input type="text" v-model="yuan_bao_scale" @input='yuanBaoInputInput' @change='yuanBaoInputChange' class="yuan_bao_input" placeholder="10" /><span style="padding: 0 10px;font-size: 13px;">请输入10的整数倍</span>
                                <div style="color:red">由于系统延迟，改价后将在5分钟内显示订单。</div>
                            </div>
                        <div class="xian_yu_span">
                            <span >出售总价：</span>       
                            <span style="margin-right:15px;color:red">￥{{guidance_price}}</span>       
                            <span >到手价：</span>       
                            <span style="color:red">￥{{saleMoney}}</span>
                        </div>    
                        </div>
                    </div>
                    <div class="pop_btns">
                        <van-button round type="info" size="small" color="linear-gradient(to right, #FF704D, #F73727)" @click="onConfirm">上架</van-button>
                        <van-button plain round type="default" size="small" @click="cancelHandle">取消</van-button>
                    </div>
                </van-popup>
                <van-popup v-model="recaptionShow" position="top">
                    <div class="pop_w">
                        <p>取回后角色可重新登录游戏，再次上架是需重新审核，您确定要取回吗？</p>
                    </div>
                    <div class="pop_btns">
                        <van-button round type="info" size="small" color="linear-gradient(to right, #FF704D, #F73727)" @click="recaptionConfirm">取回</van-button>
                        <van-button plain round type="default" size="small" @click="recaptionShow=false;">取消</van-button>
                    </div>
                </van-popup>
                <van-popup v-model="applyShow" position="top">
                    <div class="pop_w">
                        <p>是否确定取消上架申请？</p>
                    </div>
                    <div class="pop_btns">
                        <van-button round type="info" size="small" color="linear-gradient(to right, #FF704D, #F73727)" @click="applyConfirm">确认</van-button>
                        <van-button plain round type="default" size="small" @click="applyShow=false;">取消</van-button>
                    </div>
                </van-popup>
                <van-popup v-model="delShow" position="top">
                    <div class="pop_w">
                        <p>是否确认删除订单？</p>
                    </div>
                    <div class="pop_btns">
                        <van-button round type="info" size="small" color="linear-gradient(to right, #FF704D, #F73727)" @click="delConfirm">确认</van-button>
                        <van-button plain round type="default" size="small" @click="delShow=false;">取消</van-button>
                    </div>
                </van-popup>
            </div>
        </div>
        <van-dialog v-model="show" title="提示" theme='round-button' confirmButtonText="知道了">
            <!-- <div>
                <img style="width:3rem;padding-top: 8px;" src="@/assets/images/200069.png" />
            </div> -->
            <div style="color: #646566;padding: 6px 24px;font-size: 14px;line-height: 20px;">{{dialog_mes}}</div>
        
        </van-dialog>
    </div>
</template>
<script>
 import Top from '@/components/top.vue'

export default {
    name: 'Saletrade',
    components: {
        Top,
    },
    data() {
        return {
            saleMoney:'',
            guidance_price:'',
            ruleData: {
                equipmentSellingInformation: 0,
                equipmentInformationMin: 0,
                equipmentInformationMax: 0,
                currencySellingInformation: 0,
                currencyInformationMin: 0,
                currencyInformationMax: 0,
                equipmentPublicityPeriod: 0,
                currencyPublicityPeriod: 0,
                salePeriod: 0,
            },
            scale:'',
            goods_item:{},
            showPicker:false,
            groundingScale:[],
            groundingScaleData:[],
            onsale_type:'',
            dialog_mes:'',
            show:false,
            saleActive: 'onshelves',
            loading: false,
            finished: false,
            error: false,
            list: [],
            pullShow: false,
            onShow: false,
            on_xian_yu:false,
            recaptionShow: false,
            applyShow: false,
            delShow: false,
            total: 0,
            page: 1,
            goodsId: '',
            rolePrice: '',
            firstPrice: '',
            percent: '',
            clickoverlay: false,
            setKeyboard: false,
            channel_id:'',
            yuan_bao_scale:''
        }
    },
    mounted(){
        this.$nextTick(function () {
            window.addEventListener('focusin', () => {
                // var resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
                // alert(resizeHeight);
                // 键盘弹出事件处理
                //this.$store.commit('setKeyboard', true);
                //alert('键盘弹起')
                this.setKeyboard = true;
            });
            window.addEventListener('focusout', () => {
                // 键盘收起事件
                //this.$store.commit('setKeyboard', false);
                // var resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
                // alert('键盘关闭')
                setTimeout(() => {
                    this.setKeyboard = false;
                }, 200);
            });

        })
    },
    created(){
        switch(this.$route.query.type){
            case '1':
                this.saleActive = 'onshelves'
                break;
            case '2':
                this.saleActive = 'pullshelves'
                break;
            case '3':
                this.saleActive = 'sold'
                break; 
        }
    },
    methods: {
        //输入元宝比例后计算
         yuanBaoInputInput(e){
            //  this.scale = this.yuan_bao_scale;
            let aaa='';
            // let item=this.groundingScaleData.filter((item)=>item.money_scale==this.scale);
            // aaa=item[0].rebate
            this.guidance_price = Math.floor(Number( (this.goods_item.item_num - this.goods_item.sell_coin ) * 10000 / this.yuan_bao_scale)*100 )/100;  //出售总价
            let infofee = 0,
            truefee = 0;
            infofee =this.guidance_price * this.ruleData.currencySellingInformation * 0.01;
            if (infofee < this.ruleData.currencyInformationMin) {
            truefee = this.ruleData.currencyInformationMin;
            } else if (infofee > this.ruleData.currencyInformationMax) {
            truefee = this.ruleData.currencyInformationMax;
            } else {
            truefee = infofee;
            }
            this.saleMoney =Math.floor( Number(this.guidance_price - truefee < 0 ? 0 : this.guidance_price - truefee)*100)/100;//到手价
            if (this.yuan_bao_scale==0) {
                this.guidance_price=0
                this.saleMoney=0
                }
         },
         //输入元宝比例的提示
        yuanBaoInputChange(){
       if (this.yuan_bao_scale%5!=0 || this.yuan_bao_scale==0) {
         this.$toast({
              message: "出售比例请输入10的整倍数",
              duration: 1500,
            });
       }
    },
        //上架比例列表 scale_list
        getScaleGoods(){
          let data=[];
         this.$api.shopping.scale_list().then((res)=>{
         if (res.code==200) {
           data=res.data
           this.groundingScaleData=res.data;
          this.groundingScale = data.map((item)=>{
              return item.money_scale
           })
         }
        //  console.log(this.groundingScaleData);
       })
    },
    onConfirm1(value){
        this.scale = value;
        let aaa='';
        let item=this.groundingScaleData.filter((item)=>item.money_scale==this.scale);
        aaa=item[0].rebate
        this.guidance_price = Math.floor(Number( (this.goods_item.item_num - this.goods_item.sell_coin ) * 10000 / aaa)*100 )/100;  //出售总价
        let infofee = 0,
        truefee = 0;
        infofee =this.guidance_price * this.ruleData.currencySellingInformation * 0.01;
        if (infofee < this.ruleData.currencyInformationMin) {
          truefee = this.ruleData.currencyInformationMin;
        } else if (infofee > this.ruleData.currencyInformationMax) {
          truefee = this.ruleData.currencyInformationMax;
        } else {
          truefee = infofee;
        }
        this.saleMoney =Math.floor( Number(this.guidance_price - truefee < 0 ? 0 : this.guidance_price - truefee)*100)/100;//到手价
        this.showPicker = false;
      },
      //取消输入金额/比例
      cancelHandle(){
       this.onShow=false;
       this.on_xian_yu=false;
       this.scale='';
       this.rolePrice=''
       this.yuan_bao_scale=''
       this.saleMoney=''
       this.guidance_price=''
      },
        //标签切换
        saleChange(name) {
            // console.log(this.saleActive)
            if(this.saleActive=='pullshelves'){
                // 未上架
                this.$router.push({
                    path: '/trade/saletrade',
                    query: {
                        type:2
                    }
                })
            }else if(this.saleActive=='sold'){
                // 已售出
                this.$router.push({
                    path: '/trade/saletrade',
                    query: {
                        type:3
                    }
                })
            }else{
                // 上架中
                this.$router.push({
                    path: '/trade/saletrade',
                    query: {
                        type:1
                    }
                })
            }
            // console.log(33333)
            this.clearHttpRequestingList();
            this.error = false;
            this.loading = true;
            this.list = [];
            this.page = 1;
            // if (this.finished) {
            this.finished = false;
            // }else{
                // this.finished = false;
                
            // }
            this.onLoad();
        },
        //点击遮罩层
        overlayClick() {
            if (this.setKeyboard) {
                this.onShow = true;
            }else{
                this.onShow = false;
                this.rolePrice=''
            }
        },
        //列表加载
        onLoad() {
            // 异步更新数据 
            let active = this.saleActive == 'onshelves' ? 2 : (this.saleActive == 'pullshelves' ? 1 : 3);
            this.$api.account.trade({
                status: active,
                page: this.page
            }).then(res => {
                if (res.code == 200) {
                    if (res.data.data.length > 0) {
                        //this.list = res.data.data;
                        res.data.data.forEach((item) => {
                            this.list.push(item);
                        });
                        this.total = res.data.total;
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        if (this.page * 8  >= this.total) {
                            this.finished = true;
                        }
                        this.page ++;
                        //关掉错误提示
                        this.error = false;
                        this.list.forEach((item) => {
                            //获取被下架的状态样式
                            item.isPull = item.status == 7 ? true : false;
                            //获取上架中的状态样式
                            item.isActive = item.status == 2 ? false : true;
                            if (item.onsale_type != 1) {
                                var imgSrc = '';
                                try{
                                    imgSrc = require('../../assets/images/itemicon/' + item.channel_id + '/' + item.icon_id + '.png');
                                }catch(e){
                                    imgSrc = require('../../assets/images/question.png');
                                }
                                this.$set(item, 'imgSrc', imgSrc);
                                return false;
                            }
                            //获取头像
                            if (item.sex == 200) {
                                switch(item.job) {
                                    case '战士':
                                        item.imgSrc = require('../../assets/images/photos/zs-nan@2x.png');
                                        break;
                                    case '法师':
                                        item.imgSrc = require('../../assets/images/photos/fa-nan@2x.png');
                                        break;
                                    default:
                                        item.imgSrc = require('../../assets/images/photos/ds-nan@2x.png');
                                }
                            }else{
                                switch(item.job) {
                                    case '战士':
                                        item.imgSrc = require('../../assets/images/photos/zs-nv@2x.png');
                                        break;
                                    case '法师':
                                        item.imgSrc = require('../../assets/images/photos/fa-nv@2x.png');
                                        break;
                                    default:
                                        item.imgSrc = require('../../assets/images/photos/ds-nv@2x.png');
                                }
                            }
                        });
                    }else{
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        this.finished = true;
                    }
                }else{
                    //加载状态结束
                    this.loading = false;
                    //错误提示
                    this.error = true;
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //确认下架
        pullConfirm() {
            this.pullShow = false;
            this.opeRequest('','', 7);
        },
        //确认上架
        onConfirm() {
            // this.onShow = false;
            // console.log(this.rolePrice);
            // console.log(this.scale);
            let aaa='';
            if ((this.onsale_type==2 && (this.channel_id==18 || this.channel_id==33))||this.onsale_type==4) {
               let item=this.groundingScaleData.filter((item)=>item.money_scale==this.scale);
               aaa=item[0].rebate
            }else if (this.onsale_type==2 && (this.channel_id==0 || this.channel_id==9)) {
                if (this.yuan_bao_scale%5!=0 || this.yuan_bao_scale==0) {
                    this.$toast({
                        message: "出售比例请输入10的整倍数",
                        duration: 1500,
                        });
                    return
                }
                aaa=this.yuan_bao_scale
            }
            // console.log(this.rolePrice);
            this.opeRequest(this.rolePrice,aaa, 2);
        },
        //确认取回
        recaptionConfirm() {
            this.recaptionShow = false;
            this.opeRequest('','', 4);
        },
        //确认取消申请
        applyConfirm() {
            this.applyShow = false;
            this.opeRequest('','', 4);
        },
        //确认删除订单
        delConfirm() {
            this.delShow = false;
            this.opeRequest('','', 5);
        },
        //点击按钮操作
        opeClick(e, item, id) {
            this.goodsId = item.goods_id;
            this.firstPrice = item.first_price;
            this.percent = item.adjustment;
            this.onsale_type=item.onsale_type;
            this.goods_item=item;
            this.channel_id=item.channel_id
            // console.log(item);
            switch (id) {
                case 1:
                    //下架
                    //阻止事件的默认行为
                    //e.preventDefault();
                    this.pullShow = true;
                    break;
                case 2:
                    //上架
                    // console.log(item);
                     //获取交易规则信息
                     this.saleMoney='';
                     this.guidance_price='';
                        this.$api.sale
                            .getRule({ channel_id: item.channel_id })
                            .then((res) => {
                            if (res.code == 200) {
                                this.ruleData = res.data;
                            }
                            });
                    if (item.onsale_type==2 || item.onsale_type==4) {
                      this.getScaleGoods();
                      this.on_xian_yu=true
                    }else{
                      this.onShow=true;
                    }
                    break;
                case 3:
                    //取回
                    this.recaptionShow = true;
                    break;
                case 4:
                    //取消申请
                    this.applyShow = true;
                    break;
                default:
                    //删除记录
                    //阻止事件的默认行为
                    //e.preventDefault();
                    this.delShow = true;
            }
        },
        //商品操作请求
        opeRequest(price,scale, status) {
            // console.log(price);
            // console.log(scale);
            this.$api.account.goodsOperate({
                goods_id: this.goodsId,
                price: price,
                status: status,
                scale:scale
            }).then(res => {
                if (res.code == 200) {
                    this.on_xian_yu=false;
                    this.onShow = false;
                    this.scale='';
                    this.rolePrice=''
                    this.$toast({
                        message: res.data,
                        duration: 1500
                    });
                    this.list = [];
                    this.page = 1;
                    this.finished = false;
                    this.loading = true;
                    this.onLoad();
                }else if(res.code == 10000){
                    this.show = true;
                    this.dialog_mes = res.msg
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //点击跳转到商品详情
        listClick(e, item) {
            if (e.target.nodeName != 'BUTTON') {
              if(item.onsale_type != 2 && item.onsale_type != 4){
                    let params = {
                    channel_id: item.channel_id,
                    goods_id: item.goods_id
                }, linkName = 'characterDetails';
                switch (item.status) {
                    case 2:
                        params.is_from = '';
                        break;
                    case 8:
                        params.is_from = 'onSale';
                        break;
                    case 3:
                        params.is_from = 'sold';
                        break;
                    default: 
                        params = {};
                        linkName = '';
                }
                this.$router.push({
                    name: linkName,
                    query: params
                }); 
                }
            }
        },
    },

}
</script>
<style scoped>
   .xian_yu{
       height: 100px;
       width: 100%;
       padding: 10px 0;
   }
   .xian_yu_span{
    height: 3rem;
    line-height: 3rem;
    font-size: 0.95rem;
    font-weight: 600;
   }
   .no_up_css{
    position: absolute;
    top: 49px;
    right: 15px;
   }
   /* 冰雪货币交易 */
    .yuan_bao_input{
    border: 1px #ccc solid;
    padding: 4px 10px;
    width: 110px;
    font-size: 1rem;
    }
    .bing_xue_calc_box{
    height: 50px;
    padding-top: 5px;
    font-size: 15px;
    white-space: nowrap;
    }
</style>

